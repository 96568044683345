//import i18n from 'i18next';
import icon_background from '../../../assets/icons/sidebar/background.svg';
import icon_myDesigns from '../../../assets/icons/sidebar/my_designs.svg';
import icon_text from '../../../assets/icons/sidebar/text.svg';
import icon_clipart from '../../../assets/icons/sidebar/clipart.svg';
import icon_images from '../../../assets/icons/sidebar/images.svg';
import icon_layers from '../../../assets/icons/sidebar/layers.svg';
import icon_desing from '../../../assets/icons/sidebar/designs.svg';
import {objectTypes} from '../../../helpers/canvasHelper';

const i18n = {
  t: v => v,
};

export const getSideBarMenuList = objects => {
  const bgObject = objects.find(i => i?.type === objectTypes.background);
  const mainMenuList = [
    // {id: 1, img: icon_myDesigns, title: i18n.t('Design')},
    {id: 2, img: icon_images, title: i18n.t('Image')},
    {id: 3, img: icon_text, title: i18n.t('Text')},
  ];
  const bgMenuItem = {id: 4, img: icon_background, title: i18n.t('Background')};

  return bgObject?.personalization?.length
    ? [bgMenuItem, ...mainMenuList]
    : mainMenuList;
};
