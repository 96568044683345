import * as S from './styled';
import {useState} from 'react';

export const QuantityCounter = ({
  minQuantity,
  maxQuantity,
  quantity,
  setQuantity,
  disabled,
}) => {
  const minimum = quantity <= minQuantity;
  const maximum = quantity >= maxQuantity;
  const [focus, setFocus] = useState(false);

  return (
    <S.Counter>
      <S.CounterBtn
        $isdisable={minimum || disabled}
        onClick={() => !minimum && setQuantity(quantity - 1)}>
        -
      </S.CounterBtn>
      <S.CounterQuantity>
        <S.CounterQuantityInput
          value={focus ? quantity : quantity + '%'}
          onBlur={() => setFocus(false)}
          onFocus={() => setFocus(true)}
          onChange={e => setQuantity((e.target.value).replace('%','')) }
        />
      </S.CounterQuantity>
      <S.CounterBtn
        $isdisable={maximum || disabled}
        onClick={() => !maximum && setQuantity(quantity + 1)}>
        +
      </S.CounterBtn>
    </S.Counter>
  );
};
