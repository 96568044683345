import * as S from './styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

export const Header = props => {
  const {name} = props;
  return (
    <S.Header>
      <S.BackBlock
        onClick={() => window.parent.postMessage('closeButtonClicked', '*')}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </S.BackBlock>
      <S.HeaderTitle>{name || '-'}</S.HeaderTitle>
    </S.Header>
  );
};
