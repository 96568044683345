import styled, {css} from 'styled-components';
import {InputBlock, WrapperInput} from '../../components/inputField/styled';
import {ItemWr} from '../../components';

export const DividingLine = css`
  &:after {
    content: '';
    position: absolute;
    width: calc(100% + 40px);
    height: 0.5px;
    background-color: ${props => props.theme.borderColor};
    left: -20px;
    bottom: 0;

    ${props => props.theme.screenSize.lg} {
      width: calc(100% + 20px);
      left: -10px;
    }
  }
`;

export const ColorPickerBlock = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

export const ColorPickerLabel = styled.div`
  //display: ${props => (props.$isActive ? 'block' : 'none')};
`;
export const SidebarWr = styled.div`
  //border-left: 1px solid #e6e6e6;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const SidebarTab = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

export const BlockTools = styled.aside`
  display: ${props => (props.$isVisible ? 'block' : 'none')};
  // border: solid 1px ${props => props.theme.borderColor};
  // border-radius: ${props => props.theme.radiusButton};
  padding: 18px 24px;

  ${props => props.theme.screenSize.lg} {
    padding: 18px 10px;
  }
`;

export const InputMobileWrapper = styled.div`
  width: 100%;
  textarea {
    border: 1px solid #252525;
  }
`;

export const BlockText = styled.div``;

export const BlockTextHeader = styled.header`
  position: relative;
  padding-bottom: ${props => (props.$isSubElements ? '18px' : '0')};
  display: flex;
  flex-direction: column;
  row-gap: ${props => props.theme.semiBig};

  ${props => props.$isSubElements && DividingLine};
`;
export const BlockTextInput = styled.div`
  display: flex;
  column-gap: ${props => props.theme.semiBig};
  flex-wrap: wrap;

  ${ItemWr} {
    width: auto;

    ${ColorPickerBlock} {
      width: 100%;
      height: 40px;
      margin-bottom: 20px;
    }
  }

  ${InputMobileWrapper} {
    flex: 2;
  }

  ${WrapperInput} {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;

    h4 {
      font-size: ${props => props.theme.small};
      font-family: ${props => props.theme.mainFont};
    }
  }

  ${InputBlock} {
    width: 100%;
    margin-top: 0;

    input[type='checkbox'] {
      margin: 0;
      width: auto;
    }
  }

  ${props => props.theme.screenSize.lg} {
    flex-direction: column;
    row-gap: 12px;
  }
`;
export const BlockTextWrapper = styled.div`
  display: ${props => (props.$isVisible ? 'flex' : 'none')};
  column-gap: 12px;

  ${props => props.theme.screenSize.xxl} {
    flex-direction: column;
    row-gap: 12px;
  }

  ${InputBlock} {
    width: 100%;
  }
`;
export const BlockImage = styled.div``;
export const BlockPredefinedColor = styled.div`
  display: ${props => (props.$isVisible ? 'flex' : 'none')};
  column-gap: 10px;
  padding: 18px 0;
`;
export const BlockImageFilter = styled.div`
  display: ${props => (props.$isVisible ? 'flex' : 'none')};
  padding: ${props =>
    props.$isImageBlock && props.$isSubElements ? '0 0 17px 0' : '0'};
`;

export const BlockVisibleWrapper = styled.div`
  ${WrapperInput} {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;
    margin-top: 18px;
  }

  ${InputBlock} {
    height: auto;
    margin-top: 4px;

    input {
      margin: 0 0 0 4px;
    }
  }
`;

export const ImageHeaderBlock = styled.header`
  position: relative;
  padding-bottom: ${props => (props.$isSubElements ? '18px' : '0')};
  display: ${props => (props.$isVisible ? 'flex' : 'none')};
  flex-direction: column;
  row-gap: 15px;

  ${props => props.$isSubElements && props.$isVisible && DividingLine};
`;

export const ImageSectionBlock = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 18px 0;

  ${props => props.theme.screenSize.xxl} {
    grid-template-columns: 1fr 1fr;
  }

  ${props => props.theme.screenSize.xl} {
    grid-template-columns: 1fr;
  }

  input {
    padding: 0 50px 0 0;
    text-align: center;
  }
`;
export const ImageArticleBlock = styled.article`
  display: ${props => (props.$isVisible ? 'block' : 'none')};
  position: relative;

  ${props => props.$isVisible && props.$isSubElements && DividingLine}
`;

export const ImageFooterBlock = styled.footer`
  display: ${props => (props.$isVisible ? 'block' : 'none')};
`;

export const ImageFooterHeaderBlock = styled.header`
  display: flex;
  column-gap: 10px;
  padding-top: 18px;
  align-items: center;
  margin-bottom: 18px;
`;
export const Switcher = styled.div`
    position: relative;
    width: 30px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
   background-color: ${props =>
     props.$isChecked ? props.theme.primaryColor : props.theme.lightGrayColor};
    border-radius: 10px;
    transition: background-color ease-in-out 0.4s;
    cursor: pointer;
    
    input {
        position: relative;
        cursor: pointer;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 0;
        margin: 0;
    }
    
    &:after {
        content: "";
        position: absolute;
        left: ${props => (props.$isChecked ? '55%' : '3px')};
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 90px;
        transition: 0.3s;
`;

export const SidebarActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow: auto;
  height: 100%;
  padding: 25px;
  overflow-y: auto;
`;

export const BgWr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
  }
`;

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  max-height: 70px;
  height: 100%;

  button {
    border: none;
    display: inline-block;
    border-radius: 10px;
    padding: 14px 30px 12px 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: ${props => props.theme.semiMiddle};
    text-align: center;
    letter-spacing: 0.02em;
    border: 0;
    color: #fff;
    background-color: ${props => props.theme.primaryColor};
    width: 60%;
    height: 70%;
  }
`;

export const PriceBlock = styled.div`
  font-weight: 500;
  font-size: 20px;
`;

export const AddDesign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;

  .add-plus {
    color: #3e68f6;
    font-size: 25px;
    font-weight: 600;
  }
  .add-content {
    display: flex;
    flex-direction: column;
    gap: 3px;

    h4 {
      font-size: ${props => props.theme.middle};
      font-family: ${props => props.theme.mainFont};
      font-weight: 500;
    }

    h5 {
      font-size: ${props => props.theme.normal};
      font-family: ${props => props.theme.mainFont};
      font-weight: 400;
      color: #777;
      word-break: break-word;
    }
  }

  .wrapper-add-design {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 15px;
  }

  .buttons-wrapper {
    display: flex;
    column-gap: 10px;

    .position-btn {
      cursor: pointer;
      width: 38px;
      background-color: transparent;
      border: solid 1px ${props => props.theme.borderColor};
      border-radius: ${props => props.theme.radiusButton};
      transition: background-color ease-in-out 0.3s;

      &:hover {
        background-color: ${props => props.theme.hoverColor};
      }
    }
  }

  .img-wr {
    cursor: pointer;
    border: 1px solid #d1d1d1;
    border-radius: 3px;
    min-width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color ease-in-out 0.3s;

    img {
      width: 15px;
      height: 15px;
    }

    &:hover {
      background-color: ${props => props.theme.hoverColor};
    }
  }
`;

export const WrapperAddDesign = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 15px;
`;

export const BgBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  ${props => props.theme.screenSize.lg} {
    flex-direction: column;
    align-items: start;
  }
`;

export const OptionsItem = styled.div`
  textarea {
    border: 1px solid #252525;
  }
`;

export const OptionsItemLabel = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-left: ${props => props?.$marginLeft};
  color: rgb(0, 0, 0);
`;

export const OptionsItemValues = styled.div`
  display: ${props => (props?.option === 'Maat' ? 'grid' : 'flex')};
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 7px;
  .form-select {
    border: 1px solid #252525;
    height: 53px;
  }
  .checkbox-style {
    //margin-top: -7px !important;
    height: 30px;
  }
  .position-btn {
    background: transparent;
    color: #252525 !important;
    font-weight: 300 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0;
    &:hover {
      background: transparent;
    }
  }

  .form-check-input {
    margin-top: 0;
  }
  .dropzone {
    margin-top: 10px;
  }
`;

export const OptionsItemValue = styled.div`
  cursor: pointer;
  border: 1px solid
    ${props =>
      props.selected ? props.theme.secondaryColor : props.theme.borderColor};
  color: ${props =>
    props.selected ? props.theme.secondaryColor : props.theme.borderColor};
  padding: ${props => props.theme.spaceSmall}
    ${props => props.theme.spaceNormal};
  border-radius: 5px;
  flex: ${props => (props?.$itemsLength > 2 ? 'none' : 1)};
  // margin-left: ${props =>
    props?.$index % 2 ? props.theme.spaceSmall : '0px'};
  // margin-right: ${props =>
    props?.$index % 2 ? '0px' : props.theme.spaceSmall};
  background-color: ${props => props.theme.whiteColor};
  text-align: center;
  font-weight: 500;
  font-size: 12px;
`;

export const OptionItemValueColor = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: ${props =>
    props.selected
      ? `3px solid ${props.theme.blackColor}`
      : `1px solid ${props.theme.borderColor}`};
  transition: ${props => props.theme.generalTransition};
  color: ${props =>
    props.selected ? props.theme.secondaryColor : props.theme.borderColor};
  border-radius: 12px;
  flex: ${props => (props?.$itemsLength > 2 ? 'none' : 1)};
  margin-left: ${props => (props?.$index % 2 ? props.theme.spaceSmall : '0px')};
  // margin-right: ${props =>
    props?.$index % 2 ? '0px' : props.theme.spaceSmall};
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: ${props => props.color && props.color};
  &:before {
    content: '';
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    color: ${props => props.theme.blackColor};
    display: ${props => (props.selected ? 'inline-block' : `none`)};
    height: 7px;
    width: 4px;
    border-bottom: 3px solid ${props => props.theme.blackColor};
    border-right: 3px solid ${props => props.theme.blackColor};
    margin-bottom: 5px;
    transform: rotate(45deg);
  }
`;
export const TextWrapper = styled.p`
  width: 100%;
`;

export const TextAreaWr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  textarea {
    width: 100%;
    border-radius: 5px;
    font-size: 13px !important;
  }
`;

export const WrapperBtnUpload = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;
  button {
    border-radius: 5px;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 12px;
  }
  .dropzone {
    min-width: auto;
  }
`;

export const RequestImageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 0 10px 0 10px;
`;

export const ErrorQuality = styled.div`
  color: ${props => props.theme.errorColor};
  margin-top: 5px;
  font-size: ${props => props.theme.small};
  font-weight: 400;
  font-family: ${props => props.theme.mainFont};
`;
export const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 20px 20px 20px;
`;
export const ConfirmButton = styled.div`
  margin-left: 15px;
`;

export const WrappedRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 40px;
  align-items: center;
`;

export const PredefinedImageColumn = styled.div`
  flex-direction: column;
  cursor: pointer;
  padding: 0 5px;
`;

export const ImageBackgroundWrapper = styled.div`
  width: 120px;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const PredefinedImageName = styled.div`
  text-align: center;
`;

export const SliderWithInputRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SliderWithInputRowSlider = styled.div`
  flex: 5;
`;

export const SliderWithInputRowInput = styled.div`
  flex: 1;
  margin-left: 10px;
  width: 250px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 10px;
  height: 122px;
  background: #ffffff;
  cursor: pointer;
  svg {
    font-size: 44px;
    color: rgb(17, 31, 106);
  }
  img {
    max-width: 120px;
    max-height: 120px;
    width: auto;
    height: auto;
  }
  div {
    font-weight: 400;
    color: gray;
    font-size: 14px;
  }
`;

export const CreateSideBar = styled.div`
  position: relative;
  max-width: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  transition-delay: 0.4s;
  transition: 0.6s;
  background-color: #ffffff;
  z-index: 2;
  border-right: ${props =>
    props.$active
      ? `1px solid ${props.theme.primaryColor}`
      : props.$popupSideBar
      ? `1px solid ${props.theme.primaryColor}`
      : 'solid 1px #d5d5d5'};
  box-shadow: ${props => props.$active && '1px 0px 3px rgb(94, 154, 235)'};
`;

export const CreateSideBarMenuList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  list-style-type: none;
  height: 100%;
  overflow-y: hidden;
`;

export const CreateSideBarMenuItem = styled.li`
  font-size: 25px;
  width: 100%;
  height: 70px;
  transition: background-color 0.4s ease;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${props => (props.$active ? 'rgb(231 231 231)' : 'none')};
  padding: 0 10px;
  svg {
    color: ${props => props.theme.primaryColor};
  }
  span {
    font-size: 12px;
    font-weight: 600;
    color: #1e1e1e;
  }

  &:hover {
    background: rgba(231, 231, 231, 0.6);
    transition: 0.4s;
  }
`;

export const LayersListItem = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  display: grid;
  border: 2px ${p => (p?.$isSelected ? '#3e68f6' : 'lightgray')} solid;
  margin: 6px;
  border-radius: 5px;
  align-items: center;
  justify-items: center;
  cursor: pointer;
`;

export const LayersListItemIcon = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  pointer-events: none;
`;

export const LayersListItemTrash = styled.div`
  grid-row: 1;
  grid-column: 1;
  align-self: flex-end;
  justify-self: flex-end;
  cursor: pointer;
`;

export const LayersListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px;
`;

export const AddNewButton = styled.div`
  display: flex;
  align-items: center;
  margin: 0 20px 20px 20px;
  button {
    border: none;
    display: inline-block;
    border-radius: 10px;
    padding: 14px 30px 14px 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: ${props => props.theme.semiMiddle};
    text-align: center;
    border: 0;
    color: #fff;
    background-color: ${props => props.theme.primaryColor};
    height: 70%;
    width: 100%;
  }
`;

export const ImageListItemIcon = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
`;

export const SelectedObject = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 100px;
  max-width: ${props => props.scale * 100}px;
`;

export const PreviewWithLabelWrapper = styled.div`
  margin: 20px;
`;

export const PreviewWrapper = styled.div`
  border-radius: 10px;
  border: 1px lightgray solid;
  padding: 10px 20px;
`;

export const PreviewLabel = styled.div``;

export const AlignWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
`;
export const AlignItem = styled.div`
  list-style-type: none;
  cursor: pointer;

  &:hover {
    background-color: #e7e7e799;
  }

  svg {
    width: 19px;
    height: 19px;
    color: black;
    padding: 0 5px 0 5px;
  }
`;

export const RequestNewImage = styled.div`
  margin: 20px;
  border: 1px solid #3e68f6;
  color: #3e68f6;
  border-radius: 10px;
  padding: 40px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
`;

export const BlockCheckbox = styled.div`
  ${InputBlock} {
    width: 20px;
    input[type='checkbox'] {
      margin: 0;
      width: auto;
    }
  }
`;

export const Slider = styled.div`
  input {
    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
    }
  }
`;

export const MirrorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75px;
  height: 40px;
  justify-content: space-between;
`;

export const MirrorItem = styled.div`
  cursor: pointer;
  //&:hover {
  //  background-color: #e7e7e799;
  //}
`;

export const MirrorImg = styled.img`
  background-color: #ddd;
  width: 32px;
  height: 32px;
`;
