import React from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUpRightAndDownLeftFromCenter,
  faHand,
  faEdit,
  faRotate,
} from '@fortawesome/free-solid-svg-icons';
import * as S from './styled';
import {ButtonWrapper, QuantityCounter} from '../../components';

export const Footer = props => {
  const {
    size,
    setSize,
    onFitToPageClicked,
    onAddToCartClick,
    onPreviewClick,
    editMode,
    setEditMode,
    canvasRotation,
    setCanvasRotation,
    loading,
    sideBarWidth,
    isVertical,
    windowWidth,
  } = props;
  const {t} = useTranslation(['footer']);

  const showMoreLessButton = (
    <div
      style={
        {
          // minWidth: isVertical ? 140 : sideBarWidth - 80,
          // marginLeft: 80,
        }
      }></div>
  );

  return (
    <S.Footer $isVertical={isVertical}>
      {!isVertical && showMoreLessButton}
      <S.CanvasButtonsArea $isVertical={isVertical}>
        <div style={{display: 'flex', marginTop: 10, marginBottom: 10}}>
          <S.EditModeWr>
            <FontAwesomeIcon
              icon={faEdit}
              onClick={() => setEditMode(true)}
              color={editMode ? '#3e68f6' : 'lightGray'}
            />
            <FontAwesomeIcon
              icon={faHand}
              onClick={() => setEditMode(false)}
              color={!editMode ? '#3e68f6' : 'lightGray'}
            />
            <FontAwesomeIcon
              className={'rotate-icon'}
              icon={faRotate}
              onClick={() =>
                setCanvasRotation(
                  canvasRotation < 270 ? canvasRotation + 90 : 0,
                )
              }
              color={'#3e68f6'}
            />
          </S.EditModeWr>
          <S.CounterWr>
            <h3>{t('View size')}</h3>
            <QuantityCounter
              minQuantity={0}
              maxQuantity={100}
              quantity={size}
              setQuantity={setSize}
            />
            <FontAwesomeIcon
              icon={faUpRightAndDownLeftFromCenter}
              onClick={onFitToPageClicked}
            />
          </S.CounterWr>
          <div style={{width: 30}} />
        </div>
        <S.AddAndPrevButtons $isVertical={isVertical}>
          {isVertical && showMoreLessButton}
          <S.PreviewButton>
            <button onClick={onPreviewClick}>{t('Preview')}</button>
          </S.PreviewButton>
          <S.AddToCart disabled={loading}>
            <button disabled={loading} onClick={onAddToCartClick}>
              {t('Add to cart')}
            </button>
          </S.AddToCart>
        </S.AddAndPrevButtons>
      </S.CanvasButtonsArea>
    </S.Footer>
  );
};
