import styled from 'styled-components';

export const Footer = styled.div`
  min-height: ${p => (p.$isVertical ? '110px' : '70px')};
  ${props => props.theme.screenSize.xl} {
    min-height: ${p => (p.$isVertical ? '102px' : '62px')};
  }
  width: 100%;
  max-height: 10vh;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CounterWr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  gap: 10px;
  font-size: 12px;
  h3 {
    ${props => props.theme.screenSize.xl} {
      font-size: 11px;
    }
    font-weight: 500;
  }

  button {
    background-color: ${props => props.theme.primaryColor};
    border: none;
    color: #fff;
  }
  svg {
    padding: 10px;
    ${props => props.theme.screenSize.xl} {
      padding: 8px;
    }
    border: #d1d1d1 1px solid;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const AddToCart = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  min-width: 140px;
  button {
    border: none;
    display: inline-block;
    border-radius: 10px;
    min-height: 36px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: ${props => props.theme.semiMiddle};
    text-align: center;
    border: 0;
    color: #fff;
    background-color: ${props =>
      props?.disabled ? '#d1d1d1' : props.theme.primaryColor};
    height: 70%;
  }
`;

export const EditModeWr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    ${props => props.theme.screenSize.xl} {
      margin-left: 8px;
      width: 25px;
      height: 25px;
    }
    cursor: pointer;
  }
  h3 {
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
  .rotate-icon {
    margin-left: 25px;
    ${props => props.theme.screenSize.xl} {
      margin-left: 20px;
    }
  }
`;

export const AddAndPrevButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  //margin-top: ${p => (p.$isVertical ? '10px' : '0')};
  margin-bottom: ${p => (p.$isVertical ? '10px' : '0')};
  margin-right: ${p => (p.$isVertical ? '0' : '20px')};
`;

export const PreviewButton = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 20px;
  button {
    border: 1px solid ${props => props.theme.primaryColor};
    display: inline-block;
    border-radius: 10px;
    min-height: 36px;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: ${props => props.theme.semiMiddle};
    text-align: center;
    color: ${props => props.theme.primaryColor};
    background-color: #fff;
    height: 70%;
  }
`;

export const CanvasButtonsArea = styled.div`
  z-index: 10;
  display: flex;
  min-width: ${p => (p.$isVertical ? '100%' : '790px')};
  flex-direction: ${p => (p.$isVertical ? 'column' : 'row')};
  align-items: ${p => (p.$isVertical ? 'flex-end' : 'center')};
  justify-content: ${p => (p.$isVertical ? 'center' : 'flex-end')};
`;
